import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KIChat from './gpt';
import Markdown from 'react-markdown';
import { KnowledgeArticle } from '../services';
import remarkGfm from 'remark-gfm';
import { TableOfContents } from './TableOfContents';
import { SnomedConcept } from '../interfaces/Concept.interface';

interface CardProps {
  header: React.ReactNode;
  content: React.ReactNode;
  tag?: string;
  onClick: () => void;
  extraCssClasses?: string;
  icon?: string;
  isChecked?: boolean;
  onCheck?: () => void;
}

const Card: React.FC<CardProps> = ({
  header,
  tag,
  content,
  onClick,
  extraCssClasses,
  icon,
  isChecked,
  onCheck,
}) => {
  return (
    <div
      className={
        'card clickable' + (extraCssClasses ? ' ' + extraCssClasses : '')
      }
      onClick={() => onClick()}
    >
      <div className="row small-gap">
        {isChecked !== undefined && onCheck && (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => {
              e.stopPropagation();
              onCheck();
            }}
          />
        )}
        {icon && (
          <div>
            <img className={'source-icon'} src={icon} width={32} height={32} alt="" />
          </div>
        )}
        <div className="column flex1">
          <div className={'row space-between'}>
            {header}
            {tag && <div className={'tag'}>{tag}</div>}
          </div>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
};

interface SearchResultsProps {
  articles: KnowledgeArticle[];
  selectedOwners?: string[];
  isLoading: boolean;
  term: string;
  useIcons?: boolean;
  onArticleChange: (article: KnowledgeArticle | null) => void;
  chatResetKey: number;
  fetchProcedureInfo: (conceptId: string) => Promise<any>;
}

const Article: React.FC<{ article: KnowledgeArticle }> = ({ article }) => {
  const textToId = (str: string) => {
    return str
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/æ/g, 'ae')
      .replace(/ø/g, 'oe')
      .replace(/å/g, 'aa')
      .replace(/[^a-z0-9-]/g, '');
  };

  const getTextFromChildren = (children: any): string => {
    if (typeof children === 'string') {
      return children;
    }

    if (Array.isArray(children)) {
      for (let i = 0; i < children.length; i++) {
        const text = getTextFromChildren(children[i]);
        if (text !== '') {
          return text;
        }
      }
      return '';
    }

    return children && children.props
      ? getTextFromChildren(children.props.children)
      : '';
  };

  return (
    <article>
      <h1>{article.shortTitle || article.title}</h1>
      {article.professionalUpdate && (
        <div className="align-left">
          Siste faglig oppdatert: {article.professionalUpdate}
        </div>
      )}
      {article.productTitle !== undefined || article.productUrl !== undefined ? (
        <div className="align-left">
          Hentet fra:{' '}
          <a href={article.productUrl} target="_blank" rel="noreferrer">
            {article.productTitle}
          </a>
        </div>
      ) : (
        ''
      )}
      {article.content && (
        <>
          <TableOfContents
            markdown={article.content.text || ''}
            textToId={textToId}
            extraChapters={[
              ...(article.content.practical !== ''
                ? [
                    {
                      title: 'Praktisk - slik kan anbefalingen følges',
                      id: 'article-h2-praktisk',
                    },
                  ]
                : []),
              ...(article.content.rationale !== ''
                ? [
                    {
                      title: 'Begrunnelse - dette er anbefalingen basert på',
                      id: 'article-h2-rationale',
                    },
                  ]
                : []),
            ]}
          />
          <Markdown>{article.content.intro}</Markdown>
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              h2(props: any) {
                return (
                  <h2
                    id={
                      props.children
                        ? textToId(getTextFromChildren(props.children))
                        : ''
                    }
                  >
                    {props.children}
                  </h2>
                );
              },
              table(props: any) {
                return (
                  <div className="table-container">
                    <table>{props.children}</table>
                  </div>
                );
              },
            }}
          >
            {article.content.text}
          </Markdown>

          {article.content.practical !== '' && (
            <>
              <h2 id={'article-h2-praktisk'}>
                Praktisk - slik kan anbefalingen følges
              </h2>
              <Markdown>{article.content.practical}</Markdown>
            </>
          )}
          {article.content.rationale !== '' && (
            <>
              <h2 id={'article-h2-rationale'}>
                Begrunnelse - dette er anbefalingen basert på
              </h2>
              <Markdown>{article.content.rationale}</Markdown>
            </>
          )}
        </>
      )}
      <br />
      {article.link && (
        <a
          className="align-left"
          href={article.link}
          target="_blank"
          rel="noreferrer"
        >
          {article.link}
        </a>
      )}
    </article>
  );
};

export const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const [selectedArticleId, setSelectedArticleId] = useState<string | null>(null);
  const [kiTriggered, setKiTriggered] = useState(false);

  const selectedArticle = props.articles.find(
    (source) => source.id === selectedArticleId
  );

  const unselectArticle = () => {
    setSelectedArticleId(null);
    props.onArticleChange(null);
  };

  const selectArticle = (id: string) => {
    setSelectedArticleId(id);
    const article = props.articles.find((source) => source.id === id);
    if (article) {
      props.onArticleChange(article);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (props.term && !props.isLoading) {
      setKiTriggered(true);
    }
  }, [props.term, props.isLoading]);

  return (
    <div className={'padded'}>
      {selectedArticle && (
        <>
          <button
            onClick={unselectArticle}
            className={'flat padded row small-gap no-margin-bottom align-center'}
          >
            <ArrowBackIcon fontSize={'small'} />
            <span>Tilbake</span>
          </button>
          <Article article={selectedArticle} />
        </>
      )}

      {!selectedArticle && (
        <div className="ki-chat" style={{ marginBottom: '20px' }}>
          <KIChat
            key={props.chatResetKey}
            term={props.term}
            fetchProcedureInfo={props.fetchProcedureInfo}
          />
        </div>
      )}
    </div>
  );
};

// 3. Update PlaceholderLoaderArticles to include isChecked and onCheck props
const PlaceholderLoaderArticles: React.FC = () => (
  <>
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}> </span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background'}
    />
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}> </span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background opacity-05'}
    />
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}> </span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background opacity-02'}
    />
  </>
);