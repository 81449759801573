import React, { ReactElement, useEffect, useState } from 'react'
import Autocomplete from '../components/Autocomplete'
import { Concept, SnomedConcept } from '../interfaces/Concept.interface'
import { SearchResults } from '../components/SearchResults'
import {
  apiUrl,
  conditionSearchByCodeApi,
  KnowledgeArticle,
  KnowledgeArticlesBySource,
  streamFromLLM,
} from '../services'
import Header from '../components/header'
import Footer from '../components/footer'
import Select from 'react-select'
import '../index.css'
import UserProfile from '../components/UserProfile'
import { useMsal } from '@azure/msal-react'
import useCustomerConfig from '../hooks/useCustomerConfig'
import Markdown from 'react-markdown'
import { ChatBox } from '../components/ChatBox'
import { apiSearchScopeAction } from '../authConfig'
import { headers, terminlogyServer } from '../config'
import FilterListIcon from '@mui/icons-material/FilterList'

const flattenArticlesAndRemoveDuplicatesAndSort = (
  articlesBySource: KnowledgeArticlesBySource,
) => {
  const priorityOrder = [
    'NEL',
    'VAR',
    'Helsedirektoratet',
    'BMJ',
    'Felleskatalogen',
  ];
  const articles = Object.values(articlesBySource).flat();
  const uniqueArticles = articles.filter(
    (article, index, self) =>
      index === self.findIndex((t) => t.id === article.id),
  );
  return uniqueArticles.sort((a, b) => {
    const aa = priorityOrder.indexOf(a.owner[0]);
    const bb = priorityOrder.indexOf(b.owner[0]);
    return aa - bb;
  });
};

const askSnowstorm = async (
  conceptRefset: string,
  mainBranch: string,
  searchString: string,
) => {
  const queryParams = {
    active: 'true',
    conceptActive: 'true',
    offset: '0',
    limit: '20',
    groupByConcept: 'true',
    conceptRefset: conceptRefset,
    term: searchString,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${terminlogyServer}/browser/${mainBranch}/descriptions?${queryString}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: headers,
  });

  return response.json();
};

const useAccessToken = () => {
  const { instance, accounts } = useMsal();
  const getToken = async () => {
    const tokenRequest = {
      scopes: [apiSearchScopeAction],
      account: accounts[0],
    };
    try {
      const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
      return tokenResponse?.accessToken;
    } catch (error) {
      console.log('Silent token acquisition fails. Acquiring token using popup');
      try {
        const tokenResponse = await instance.acquireTokenPopup(tokenRequest);
        return tokenResponse?.accessToken;
      } catch (err) {
        console.error(err);
        return null;
      }
    }
  };
  return getToken;
};

const Main = (): ReactElement => {
  const customerConfig = useCustomerConfig();
  const getToken = useAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [term, setTerm] = useState<string | undefined>(undefined);
  const [articles, setArticles] = useState<KnowledgeArticle[]>([]);
  const [currentArticle, setCurrentArticle] = useState<KnowledgeArticle | null>(null);
  const [chatResetKey, setChatResetKey] = useState(0);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [selectedRefset, setSelectedRefset] = useState<string>(customerConfig.searchRefsets[0].id);
  const [selectedSnomedConcept, setSelectedSnomedConcept] = useState<SnomedConcept | null>(null);
  const infobuttonMode = customerConfig.infobuttonMode;

  const fetchProcedureInfo = async (conceptId: string) => {
    const token = await getToken();
    if (!token) return [];
    const results = await conditionSearchByCodeApi(conceptId, token, customerConfig.contentOwners, false);
    return flattenArticlesAndRemoveDuplicatesAndSort(results);
  };

  const filteredArticles = articles.filter((article) =>
    selectedOwners.length === 0 ? true : selectedOwners.includes(article.owner[0]),
  );

  const toggleOwner = (owner: string) => () => {
    if (selectedOwners.includes(owner)) {
      setSelectedOwners(selectedOwners.filter((o) => o !== owner));
    } else {
      setSelectedOwners([...selectedOwners, owner]);
    }
  };

  const handleSelectRefset = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRefset(e.target.value);
    setTerm('');
    setArticles([]);
    setChatResetKey((prevKey) => prevKey + 1);
    setIsPristine(true);
  };

  const lookupWithURL = async (url: URL) => {
    setIsLoading(true);
    setIsPristine(false);
    const token = await getToken();
    if (!token) return;

    const resultsRes = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const results: KnowledgeArticlesBySource = await resultsRes.json();
    setIsLoading(false);
    const articles = flattenArticlesAndRemoveDuplicatesAndSort(results);
    setArticles(articles);
  };

  const uppercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onConceptSelected = async (concept: Concept, infobuttonParams?: object) => {
    if (!concept) return;
    const token = await getToken();
    if (!token) return;
    setTerm(uppercaseFirstLetter(concept.concept.pt.term));
    setIsPristine(false);
    setCurrentArticle(null);
    setArticles([]);
    setChatResetKey((prevKey) => prevKey + 1);

    if (concept?.concept?.id) {
      setIsLoading(true);
      setSelectedSnomedConcept(concept.concept);

      const nonCachedArticlesPromise = conditionSearchByCodeApi(
        infobuttonParams ? infobuttonParams : concept.concept.id,
        token,
        customerConfig.contentOwners,
        false,
      );
      const cachedArticles = await conditionSearchByCodeApi(
        infobuttonParams ? infobuttonParams : concept.concept.id,
        token,
        customerConfig.contentOwners,
        true,
      );

      setArticles(flattenArticlesAndRemoveDuplicatesAndSort(cachedArticles));
      const nonCachedArticles = await nonCachedArticlesPromise;
      setArticles(flattenArticlesAndRemoveDuplicatesAndSort(nonCachedArticles));
      setIsLoading(false);
    }
  };

  const filterOptions = (
    <ExpandablePanel
      expandText={'Vis filtervalg'}
      collapseText={'Skjul filtervalg'}
      icon={<FilterListIcon />}
    >
      <>
        <h3>Eiere</h3>
        <ul className={'simple-list'}>
          {customerConfig.contentOwners.map((owner) => {
            const numArticlesOfOwner = articles.filter(
              (article) => article.owner[0] === owner,
            ).length;
            return (
              <li key={owner}>
                <input
                  id={'owner-' + owner}
                  type={'checkbox'}
                  checked={selectedOwners.includes(owner)}
                  onClick={toggleOwner(owner)}
                />{' '}
                <label htmlFor={'owner-' + owner}>
                  {owner}{' '}
                  <span className={'muted-text'}>({numArticlesOfOwner})</span>
                </label>
              </li>
            );
          })}
        </ul>
      </>
    </ExpandablePanel>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mainSearchCriteriaVc = params.get('mainSearchCriteria.v.c');
    if (!mainSearchCriteriaVc) return;
    const mainSearchCriteriaVdn = params.get('mainSearchCriteria.v.dn');
    const entries = params.entries();
    const entriesArray = Array.from(entries);
    const infobuttonParams = Object.fromEntries(
      entriesArray.filter(
        ([key]) =>
          key.startsWith('taskContext.') ||
          key.startsWith('subTopic.') ||
          key.startsWith('mainSearchCriteria.'),
      ),
    );

    const asConcept: Concept = {
      term: mainSearchCriteriaVdn || mainSearchCriteriaVc || 'Unknown',
      concept: {
        id: mainSearchCriteriaVc || '???',
        pt: {
          term: mainSearchCriteriaVdn || mainSearchCriteriaVc || 'Unknown',
        },
      },
    };

    setTimeout(() => {
      onConceptSelected(asConcept, infobuttonParams);
    }, 200);
  }, []);

  return (
    <>
      <Header rightSideElement={<UserProfile />} />
      <NotLoggedInWarningIfNotLoggedIn />
      <main className={'container'}>
        <section className={'padded'}>
          <div>
            <div>
              {customerConfig.searchRefsets.length > 1 && (
                <select
                  value={selectedRefset}
                  onChange={handleSelectRefset}
                  className="search-type-selector"
                >
                  {customerConfig.searchRefsets.map((refset) => (
                    <option key={refset.id} value={refset.id}>
                      {refset.label}
                    </option>
                  ))}
                </select>
              )}
              {infobuttonMode ? (
                <HL7InfobuttonForm
                  mainBranch={customerConfig.mainBranch}
                  refset={selectedRefset}
                  onSearch={(url: URL) => {
                    lookupWithURL(url);
                  }}
                />
              ) : (
                <>
                  {customerConfig.showFilterOptions && filterOptions}
                  <Autocomplete
                    onSelect={(concept) => {
                      concept && onConceptSelected(concept);
                    }}
                    placeholderText={
                      customerConfig.searchRefsets.find(
                        (r) => r.id === selectedRefset,
                      )!.label
                    }
                    selectedRefset={selectedRefset}
                    requestCompletions={(q: string) =>
                      askSnowstorm(selectedRefset, customerConfig.mainBranch, q)
                    }
                  />
                </>
              )}
            </div>
          </div>
        </section>

        <section>
          {!isPristine && (
            <SearchResults
              articles={filteredArticles}
              selectedOwners={selectedOwners}
              isLoading={isLoading}
              term={term || ''}
              useIcons={customerConfig.useIcons}
              onArticleChange={(article) => setCurrentArticle(article)}
              chatResetKey={chatResetKey}
              fetchProcedureInfo={fetchProcedureInfo}
            />
          )}
        </section>
      </main>
      {customerConfig.useChat ? (
        <>
          {currentArticle && (
            <Footer label={'KI-assistent for legemiddelbytte'}>
              <ChatBox
                askLLM={async (chat: any) =>
                  streamFromLLM((await getToken())!, chat)
                }
                medicalText={currentArticle.content.text || ''}
              />
            </Footer>
          )}
        </>
      ) : (
        isPristine && (
          <Footer label={'informasjon om tjenesten'}>
            <Markdown>{customerConfig.aboutText}</Markdown>
            <div className="row space-between align-center gap">
              {customerConfig.aboutLogos.map((logo, i) => (
                <img
                  key={i}
                  src={logo.src}
                  alt={logo.alt}
                  className={logo.className}
                />
              ))}
            </div>
          </Footer>
        )
      )}
    </>
  );
};

// ... (rest of the existing code, including NotLoggedInWarningIfNotLoggedIn, ExpandablePanel, HL7InfobuttonForm)

const NotLoggedInWarningIfNotLoggedIn = () => {
  const { accounts } = useMsal()
  if (accounts.length === 0) {
    return (
      <div className={'blue-background'}>
        <p className={'larger-text'}>Logg inn for å bruke appen</p>
        <UserProfile />
      </div>
    )
  } else {
    return null
  }
}

const ExpandablePanel: React.FC<{
  children: React.ReactNode
  expandText: string
  collapseText: string
  icon?: React.ReactNode
}> = ({ children, expandText, collapseText, icon }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <button
        className={'flat row align-center tiny-gap'}
        onClick={() => setIsOpen(!isOpen)}
      >
        {icon}
        {isOpen ? collapseText : expandText}
      </button>
      <div className={'toggled-content ' + (isOpen ? 'open' : 'closed')}>
        {children}
      </div>
    </div>
  )
}

type HL7InfobuttonFormProps = {
  refset: string
  mainBranch: string
  onSearch: (url: URL) => void
}
const HL7InfobuttonForm: React.FC<HL7InfobuttonFormProps> = ({
  refset,
  mainBranch,
  onSearch,
}) => {
  const [formData, setFormData] = useState<any>({
    'mainSearchCriteria.v.c': '',
    'mainSearchCriteria.v.cs': '2.16.840.1.113883.6.96',
    'taskContext.c.c': 'PROBLISTREV',
    performer: 'PROV',
    'informationRecipient.languageCode.c': 'en',
    knowledgeResponseType: 'text/html',
    owners: [],
  })

  const makeUrl = (formData: any) => {
    const searchParams = new URLSearchParams(
      Object.entries(formData).map(([key, value]) => {
        if (Array.isArray(value)) {
          return [key, value.map((x) => x.value).join(',')]
        } else if (typeof value === 'string') {
          return [key, value]
        } else if (value == null) {
          return [key, '']
        } else if (typeof value === 'object') {
          return [key, (value as any).value || '']
        }
      }) as any,
    )
    return new URL(`${apiUrl}/api/v1/infobutton?${searchParams.toString()}`)
  }

  const myURL: URL = makeUrl(formData)

  const schema = {
    fields: [
      {
        name: 'mainSearchCriteria.v.c',
        type: 'text',
        label: 'Code',
        required: true,
      },
      {
        name: 'mainSearchCriteria.v.cs',
        type: 'select',
        label: 'Code system',
        options: [
          { value: '2.16.840.1.113883.6.96', label: 'SNOMED-CT' },
          { value: '2.16.840.1.113883.6.3', label: 'ICD-10' },
          { value: '2.16.578.1.12.4.1.1.7110', label: 'ICPC-2' },
        ],
        required: true,
      },
      {
        name: 'taskContext.c.c',
        type: 'select',
        label: 'Task context',
        options: [
          { value: 'GENEREV', label: 'Genetic test results review' },
          { value: 'LABRREV', label: 'Lab results review' },
          { value: 'LABOE', label: 'Lab order entry' },
          { value: 'MLREV', label: 'Medication list review' },
          { value: 'MEDOE', label: 'Medication order entry' },
          { value: 'PROBLISTE', label: 'Problem list entry' },
          { value: 'PROBLISTREV', label: 'Problem list review' },
        ],
      },
      {
        name: 'performer',
        type: 'select',
        label: 'Performer',
        options: [
          { value: 'PROV', label: 'PROV' },
          { value: 'PAT', label: 'PAT' },
        ],
      },
      {
        name: 'informationRecipient.languageCode.c',
        type: 'select',
        label: 'Language code',
        options: [
          { value: 'en', label: 'en' },
          { value: 'no', label: 'no' },
        ],
      },
      {
        name: 'knowledgeResponseType',
        type: 'select',
        label: 'Format',
        options: [
          { value: 'text/html', label: 'HTML' },
          { value: 'text/markdown', label: 'Markdown' },
        ],
      },
      {
        name: 'owner',
        type: 'select',
        multiple: true,
        label: 'Owner',
        options: [
          'BMJ',
          'Helsebiblioteket',
          'Felleskatalogen',
          'Helsedirektoratet',
          'Helsenorge',
          'NEL',
          'VAR',
        ].map((owner) => ({
          value: owner.toLowerCase(),
          label: owner,
        })),
      },
    ],
  }

  return (
    <div className={'infobutton-schema'}>
      <h3>HL7 Infobutton</h3>
      <div>
        <Autocomplete
          onSelect={(concept) => {
            concept &&
              setFormData({
                ...formData,
                'mainSearchCriteria.v.c': concept.concept.id,
                'mainSearchCriteria.v.cs': {
                  value: '2.16.840.1.113883.6.96',
                  label: 'SNOMED-CT',
                },
              })
          }}
          placeholderText={'Søk opp en SNOMED-CT kode her'}
          selectedRefset={refset}
          requestCompletions={(q: string) =>
            askSnowstorm(refset, mainBranch, q)
          }
        />
      </div>

      {schema.fields.map((field) => {
        if (field.type === 'text') {
          return (
            <div key={field.name}>
              <label htmlFor={field.name}>
                {field.label}
                {field.required && <span className={'required-star'}>*</span>}
              </label>
              <input
                type="text"
                name={field.name}
                id={field.name}
                value={formData[field.name]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [field.name]: e.target.value,
                  })
                }
              />
            </div>
          )
        } else if (field.type === 'select') {
          return (
            <div key={field.name}>
              <label htmlFor={field.name}>
                {field.label}
                {field.required && <span className={'required-star'}>*</span>}
              </label>
              <Select
                isMulti={field.multiple}
                name={field.name}
                options={field.options!}
                value={formData[field.name]}
                onChange={(selected) => {
                  setFormData({
                    ...formData,
                    [field.name]: selected,
                  })
                }}
              />
            </div>
          )
        }
      })}

      <div>
        <button className={'cool'} onClick={() => onSearch(myURL)}>
          Søk
        </button>
      </div>

      <code>{myURL.toString()}</code>
    </div>
  )
}

export default Main