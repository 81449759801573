import { useEffect, useState, useRef } from 'react';
import { createThread, sendMessage } from './apigpt';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import CopyAll from '@mui/icons-material/CopyAll';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Define helper function at the top level with error handling
const extractConceptIdFromHref = (href) => {
  try {
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    return params.get('mainSearchCriteria.v.c');
  } catch (error) {
    console.error('Invalid URL:', href, error);
    return null;
  }
};

// Define ProcedureDisplay component with array check
const ProcedureDisplay = ({ conceptId, children, fetchProcedureInfo, onSelect }) => {
  const [showContent, setShowContent] = useState(false);
  const [articles, setArticles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedIndices, setSelectedIndices] = useState([]);

  const handleClick = async () => {
    setShowContent(!showContent);
    if (!showContent && !articles) {
      setLoading(true);
      try {
        const fetchedArticles = await fetchProcedureInfo(conceptId);
        setArticles(fetchedArticles || []); // Setter til tom array hvis null
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleArticleSelect = (index, checked) => {
    if (checked) {
      setSelectedIndices(prev => [...prev, index]);
    } else {
      setSelectedIndices(prev => prev.filter(i => i !== index));
    }
    if (onSelect) {
      const selectedTexts = selectedIndices.map(i => articles[i].content.text || '').join('\n\n');
      onSelect(selectedTexts);
    }
  };

  return (
    <div>
      <button onClick={handleClick}>
        {children}
      </button>
      {showContent && (
        <div>
          {loading && <div>Laster...</div>}
          {error && <div>Feil: {error}</div>}
          {articles && Array.isArray(articles) ? (
            articles.length > 0 ? (
              articles.map((article, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginBottom: '0.3em' }}>
                  <input
                    type="checkbox"
                    checked={selectedIndices.includes(index)}
                    onChange={(e) => handleArticleSelect(index, e.target.checked)}
                  />
                  <span>
                    <h3>{article.title}</h3>
                    <Markdown>{article.content.text || ''}</Markdown>
                  </span>
                </div>
              ))
            ) : (
              <div>Ingen funnet</div> // Viser "Ingen funnet" hvis ingen prosedyrer finnes
            )
          ) : null}
        </div>
      )}
    </div>
  );
};

/**
 * Parser markdown-linjer slik at:
 * - H2 (## ...) blir hovedseksjoner (f.eks. PROBLEM, MÅL, INTERVENSJONER)
 * - H3 (### ...) blir underseksjoner under hver H2-seksjon
 * - Håndterer "VAR-prosedyrer"-lenker som knapper
 */
function parseMarkdownByH2AndH3(markdownText) {
  const lines = markdownText.split('\n');
  const sections = [];
  let currentSection = null;
  let currentSubsection = null;

  lines.forEach((line) => {
    if (line.trim().startsWith('## ')) {
      if (currentSection) {
        if (currentSubsection) {
          currentSection.subsections.push(currentSubsection);
          currentSubsection = null;
        }
        sections.push(currentSection);
      }
      currentSection = {
        heading: line.trim().replace('## ', ''),
        subsections: [],
      };
    } else if (currentSection && line.trim().startsWith('### ')) {
      if (currentSubsection) {
        currentSection.subsections.push(currentSubsection);
      }
      currentSubsection = {
        type: 'normal',
        heading: line.trim().replace('### ', ''),
        content: [],
      };
    } else if (currentSection && line.match(/\[(VAR-prosedyrer)\]\(https:\/\/demo\.semanticpulse\.no\/.+\)/)) {
      if (currentSubsection) {
        currentSection.subsections.push(currentSubsection);
      }
      const match = line.match(/mainSearchCriteria\.v\.c=([0-9]+)/);
      const conceptId = match ? match[1] : null;
      currentSubsection = {
        type: 'var-prosedyrer-button',
        headingText: 'VAR-prosedyrer',
        conceptId: conceptId,
        content: [],
      };
    } else if (currentSection && currentSubsection) {
      currentSubsection.content.push(line);
    }
  });

  if (currentSection) {
    if (currentSubsection) {
      currentSection.subsections.push(currentSubsection);
    }
    sections.push(currentSection);
  }

  return sections;
}

const ProcedureSection = ({ conceptId, headingText, fetchProcedureInfo, type, isChecked, onCheck }) => {
  const [showContent, setShowContent] = useState(false);
  const [articles, setArticles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checkedProcedures, setCheckedProcedures] = useState({});

  const handleLabelClick = async () => {
    setShowContent(!showContent);
    if (!showContent && !articles) {
      setLoading(true);
      try {
        const fetchedArticles = await fetchProcedureInfo(conceptId);
        setArticles(fetchedArticles);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleProcedureCheck = (index, checked) => {
    setCheckedProcedures(prev => ({
      ...prev,
      [index]: checked,
    }));
  };

  return (
    <div className="h3-section" style={{ margin: '1em 0' }}>
      {type !== 'var-prosedyrer' ? (
        <label style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginBottom: '0.3em' }} onClick={handleLabelClick}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={onCheck}
          />
          <h3 style={{ margin: 0 }}>{headingText}</h3>
        </label>
      ) : (
        <button onClick={handleLabelClick} className="var-prosedyrer-button">
          {headingText}
        </button>
      )}
      {showContent && (
        <div style={{ marginLeft: '1.8em' }}>
          {loading && <div>Laster...</div>}
          {error && <div>Feil: {error}</div>}
          {articles && Array.isArray(articles) && articles.map((article, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginBottom: '0.3em' }}>
              <input
                type="checkbox"
                checked={checkedProcedures[index] || false}
                onChange={(e) => handleProcedureCheck(index, e.target.checked)}
              />
              <span>
                <h3>{article.title}</h3>
                <Markdown>{article.content.text || ''}</Markdown>
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ChatMessage = ({ message, fetchProcedureInfo }) => {
  const [checkedSections, setCheckedSections] = useState({});
  const [varProcedureData, setVarProcedureData] = useState({});

  if (!message?.msg) return null;

  const isStructuredMarkdown = message.isBot && (message.msg.includes('## ') || message.msg.includes('### '));

  if (!isStructuredMarkdown) {
    return (
      <div className={message.isBot ? 'bot-message' : 'user-message'}>
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        >
          {message.msg}
        </Markdown>
      </div>
    );
  }

  const sections = parseMarkdownByH2AndH3(message.msg);


  const handleVarProceduresClick = async (conceptId, setOpen) => {
    if (!varProcedureData[conceptId]) {
      const procedures = await fetchProcedureInfo(conceptId);
      setVarProcedureData(prev => ({
        ...prev,
        [conceptId]: {
          procedures: Array.isArray(procedures) ? procedures : [],
          isOpen: setOpen,
          checked: {},
        },
      }));
    } else {
      setVarProcedureData(prev => ({
        ...prev,
        [conceptId]: {
          ...prev[conceptId],
          isOpen: setOpen,
        },
      }));
    }
  };

  const handleProcedureCheck = (conceptId, procIndex, checked) => {
    setVarProcedureData(prev => ({
      ...prev,
      [conceptId]: {
        ...prev[conceptId],
        checked: {
          ...prev[conceptId].checked,
          [procIndex]: checked,
        },
      },
    }));
  };

  const handleCheckpointChange = (index, subsection, checked) => {
    setCheckedSections((prev) => {
      const newState = { ...prev };
      if (checked) {
        const combined = [subsection.heading || '', ...subsection.content]
          .filter(line => typeof line === 'string' && line && !line.trim().startsWith('## '))
          .join('\n');
        newState[index] = combined;
      } else {
        delete newState[index];
      }
      return newState;
    });
  };

  const handleCopySelection = () => {
    let selectedText = '';
    for (const [idx, text] of Object.entries(checkedSections)) {
      selectedText += text + '\n\n';
    }
    for (const [conceptId, data] of Object.entries(varProcedureData)) {
      if (data.procedures && data.checked) {
        data.procedures.forEach((proc, procIndex) => {
          if (data.checked[procIndex]) {
            selectedText += proc.title + '\n\n';
          }
        });
      }
    }
    if (selectedText.trim()) {
      navigator.clipboard.writeText(selectedText.trim());
    }
  };

  const handleCopyAll = () => {
    navigator.clipboard.writeText(message.msg || '');
  };

  return (
    <div className={message.isBot ? 'bot-message' : 'user-message'}>
      {sections.map((section, sectionIdx) => (
        <div key={sectionIdx}>
          <h2>{section.heading}</h2>
          {section.subsections.map((subsection, subIdx) => {
            const subsectionIndex = `${sectionIdx}-${subIdx}`; // Unik indeks for hver underseksjon
            if (subsection.type === 'var-prosedyrer-button' && subsection.conceptId) {
              const data = varProcedureData[subsection.conceptId] || { procedures: null, isOpen: false, checked: {} };
              return (
                <div key={subIdx} className="h3-section" style={{ margin: '1em 0' }}>
                  <button
                    className="var-procedures-button"
                    onClick={() => handleVarProceduresClick(subsection.conceptId, !data.isOpen)}
                  >
                    VAR-prosedyrer <FontAwesomeIcon icon={faCaretDown} />
                  </button>
                  {data.isOpen && (
                    <div className={`expandable-list ${data.isOpen ? 'open' : ''}`} style={{ marginLeft: '20px' }}>
                      {data.procedures === null ? (
                        <div>Laster...</div>
                      ) : Array.isArray(data.procedures) && data.procedures.length > 0 ? (
                        data.procedures.map((proc, procIndex) => (
                          <div
                            key={procIndex}
                            className="procedure-card"
                            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <input
                              type="checkbox"
                              checked={data.checked[procIndex] || false}
                              onChange={(e) => handleProcedureCheck(subsection.conceptId, procIndex, e.target.checked)}
                            />
                            <img
                              src={'assets/source-icons/varnett.png'}
                              alt="VAR"
                              width={32}
                              height={32}
                            />
                            <a href={proc.link || '#'} target="_blank" rel="noreferrer">
                              {proc.title}
                            </a>
                          </div>
                        ))
                      ) : (
                        <div>Ingen funnet</div>
                      )}
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div key={subIdx} className="h3-section" style={{ margin: '1em 0' }}>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginBottom: '0.3em' }}>
                    <input
                      type="checkbox"
                      checked={checkedSections[subsectionIndex] || false}
                      onChange={(e) => handleCheckpointChange(subsectionIndex, subsection, e.target.checked)}
                    />
                    <h3 style={{ margin: 0 }}>{subsection.heading}</h3>
                  </label>
                  {subsection.content.length > 0 && (
                    <div style={{ marginLeft: '1.8em' }}>
                      <Markdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          a: ({ href, children, ...props }) => (
                            <a href={href} target="_blank" rel="noreferrer" {...props}>
                              {children}
                            </a>
                          ),
                        }}
                      >
                        {subsection.content.join('\n')}
                      </Markdown>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
      ))}
      {message.isBot && (
        <div style={{ marginTop: '0.5em' }} className="row gap">
          <button className="flat-button row align-center small-gap" onClick={handleCopySelection}>
            <span>Kopier utvalg</span>
            <CopyAll />
          </button>
          <button className="flat-button row align-center small-gap" onClick={handleCopyAll}>
            <span>Kopier alt</span>
            <CopyAll />
          </button>
        </div>
      )}
    </div>
  );
};

const KIChat = ({ term, fetchProcedureInfo }) => {
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const endOfMessagesRef = useRef(null);
  const [userMessage, setUserMessage] = useState('');

  const handleStreamResponse = async (tid, messageContent) => {
    setLoading(true);
    try {
      const stream = await sendMessage(tid, messageContent);
      const reader = stream.getReader();
      let currentText = '';
  
      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          setChat((prevChat) => {
            const lastMessage = prevChat[prevChat.length - 1];
            if (lastMessage && lastMessage.isBot) {
              return [...prevChat.slice(0, -1), { isBot: true, msg: currentText, complete: true }];
            } else {
              return [...prevChat, { isBot: true, msg: currentText, complete: true }];
            }
          });
  
          setLoading(false);
          setIsGenerating(false);
          endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
  
        const chunk = new TextDecoder().decode(value);
        currentText += chunk;
        setChat((prevChat) => {
          const lastMessage = prevChat[prevChat.length - 1];
          if (lastMessage && lastMessage.isBot) {
            return [...prevChat.slice(0, -1), { isBot: true, msg: currentText, complete: false }];
          } else {
            return [...prevChat, { isBot: true, msg: currentText, complete: false }];
          }
        });
  
        await read();
      };
      await read();
    } catch (error) {
      console.error('Error in streaming response:', error);
      setLoading(false);
      setIsGenerating(false);
    }
  };
  
  useEffect(() => {
    const initializeChat = async () => {
      if (term && !threadId) {
        try {
          const newThreadId = await createThread();
          setThreadId(newThreadId);
          await handleStreamResponse(
            newThreadId,
            `Lag en pleieplan basert på dette snomedbegrepet ${term}`
          );
        } catch (error) {
          console.error('Failed to initialize chat:', error);
        }
      }
    };
    initializeChat();
  }, [term, threadId]);

  useEffect(() => {
    if (!loading && !isGenerating) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat, loading, isGenerating]);

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;
    setChat((prevChat) => [...prevChat, { isBot: false, msg: userMessage }]);
    setUserMessage('');

    if (threadId) {
      await handleStreamResponse(threadId, userMessage);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="ki-chat">
      <div className="chat-history column gap">
        {chat.map((message, index) => (
          <ChatMessage key={index} message={message} fetchProcedureInfo={fetchProcedureInfo} />
        ))}
        {loading && !isGenerating && (
          <div className="loader">
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
          </div>
        )}
        <div ref={endOfMessagesRef} />
      </div>

      {!isGenerating && !loading && (
        <div className="chat-input-container">
          <textarea
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Skriv din melding her..."
            className="chat-textarea"
          />
          <button
            onClick={handleSendMessage}
            className="send-button"
            disabled={isGenerating || loading || !userMessage.trim()}
          >
            Send
          </button>
        </div>
      )}
    </div>
  );
};

export default KIChat;