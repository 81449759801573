import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '55a8a72a-8247-4edf-9dd0-218068cf423d',
  meta: {
    title: 'KI-Pleieplan',
    description: 'KI app for opprettelse av pleieplan.',
  },
  aboutText: 'Dette er en tjeneste for å opprette tiltaksplaner ved bruk av kunstilg intelligens og kunnskapsintegrasjon.',
  aboutLogos: [],
  theme: {
    background: '#00ffee',
  },
  searchRefsets: [
    { id: '138441000202109', label: 'Problem' },
    { id: '138461000202105', label: 'Mål' },
    { id: '138451000202107', label: 'Intervensjon' },
  ],
  contentOwners: [
  //  'Helsebiblioteket',
  //  'Felleskatalogen',
  //  'Helsedirektoratet',
  //  'Helsenorge',
  //  'NEL',
    'VAR',
  ],
  mainBranch: 'MAIN/SNOMEDCT-NO',
  useChat: true,
  useIcons: true,
  showFilterOptions: false,
  showHitlistLink: true,
}
export default config
